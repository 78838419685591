<template>
    <div>
        <div class="row mt-5">
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    id="name"
                    v-model="itemComponent.firstName"
                    success
                    class="w-100"
                    :state="stateInputDark"
                    label="Nombres"
                    @blur="introValidators.firstName = true"
                >
                    <template
                        v-if="!isValidFirstName & introValidators.firstName"
                        #message-danger
                    >
                        Nombres no validos
                    </template>
                </vs-input>
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.lastName"
                    success
                    class="w-100"
                    label="Apellidos"
                    :state="stateInputDark"
                    @blur="introValidators.lastName = true"
                >
                    <template
                        v-if="!isValidLastName & introValidators.lastName"
                        #message-danger
                    >
                        Apellidos no validos
                    </template>
                </vs-input>
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <identification-type-selector
                    v-model="itemComponent.identificationType"
                    enabled
                />
            </div>

            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.identificationNumber"
                    success
                    class="w-100"
                    :state="stateInputDark"
                    label="Número de identificación"
                    @blur="introValidators.identificationNumber = true"
                >
                    <template
                        v-if="
                            !isValidIdentificationNumber &
                            introValidators.identificationNumber
                        "
                        #message-danger
                    >
                        Numero de indeficación no valido
                    </template>
                </vs-input>
            </div>

            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    id="phone"
                    v-model="itemComponent.phone"
                    success
                    class="w-100"
                    label="Celular de contacto"
                    type="number"
                    :state="stateInputDark"
                    @blur="introValidators.phone = true"
                >
                    <template
                        v-if="!isValidPhone & introValidators.phone"
                        #message-danger
                    >
                        Celular no valido
                    </template>
                </vs-input>
            </div>

            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.email"
                    success
                    class="w-100"
                    :state="stateInputDark"
                    label="Correo electrónico"
                    @blur="introValidators.email = true"
                >
                    <template
                        v-if="!isValidEmail & introValidators.email"
                        #message-danger
                    >
                        Email no es valido
                    </template>
                </vs-input>
            </div>

            <div class="col-12">
                <vs-checkbox v-model="needAddress" warn>
                    Agregar dirección de cliente
                </vs-checkbox>
            </div>

            <fragment v-if="needAddress">
                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <city-code-selector
                        v-model="itemComponent.cityCode"
                        enabled
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        id="address"
                        v-model="itemComponent.address"
                        success
                        :state="stateInputDark"
                        class="w-100"
                        label="Dirección"
                        @blur="onBlurAddress"
                    >
                        <template
                            v-if="!isValidAddress & introValidators.address"
                            #message-danger
                        >
                            Dirección no es valida
                        </template>
                    </vs-input>
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.addressComplement"
                        success
                        :state="stateInputDark"
                        class="w-100"
                        label="Complemento de dirección"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.neighborhood"
                        success
                        :state="stateInputDark"
                        class="w-100"
                        label="Barrio"
                    />
                </div>
            </fragment>

            <div class="d-flex justify-content-center my-4 col-8 offset-2">
                <vs-button size="large" @click="createCustomerComponent">
                    Crear cliente
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
    addressRegex,
    emailRegex,
    identificationNumberOptionalRegex,
    nameRegex,
    phoneRegex
} from "@/common/lib/regex";

import IdentificationTypeSelector from "@/components/users/IdentificationTypeSelector.vue";
import CityCodeSelector from "@/components/utils/CityCodeSelector.vue";

export default {
    name: "CreateCustomer",
    components: { IdentificationTypeSelector, CityCodeSelector },
    emits: ["create"],
    data: () => ({
        itemComponent: {
            identificationType: "cc",
            identificationNumber: "",
            firstName: "",
            lastName: "",
            cityCode: "11001",
            address: "",
            addressComplement: "",
            neighborhood: "",
            email: "",
            phone: ""
        },
        introValidators: {
            email: false,
            phone: false,
            identificationNumber: false,
            firstName: false,
            lastName: false,
            address: false
        },
        needAddress: false
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidEmail() {
            return emailRegex.test(this.itemComponent.email);
        },
        isValidIdentificationNumber() {
            return identificationNumberOptionalRegex.test(
                this.itemComponent.identificationNumber
            );
        },
        isValidFirstName() {
            return nameRegex.test(this.itemComponent.firstName);
        },
        isValidLastName() {
            return nameRegex.test(this.itemComponent.lastName);
        },
        isValidAddress() {
            return addressRegex.test(this.itemComponent.address);
        },
        isValidPhone() {
            return phoneRegex.test(this.itemComponent.phone);
        }
    },
    methods: {
        createCustomerComponent() {
            if (!this.validateData()) {
                return;
            }
            this.$emit("create", {
                identificationNumber: this.itemComponent.identificationNumber,
                identificationType: this.itemComponent.identificationType,
                firstName: this.itemComponent.firstName,
                lastName: this.itemComponent.lastName,
                email: this.itemComponent.email,
                phone: this.itemComponent.phone,
                needAddress: this.needAddress,
                location: {
                    address: this.itemComponent.address,
                    addressComplement: this.itemComponent.addressComplement,
                    cityCode: this.itemComponent.cityCode,
                    neighborhood: this.itemComponent.neighborhood,
                    receiverPhone: this.itemComponent.phone,
                    receiverName: `${this.itemComponent.firstName} ${this.itemComponent.lastName}`
                }
            });
        },
        validateData() {
            this.introValidators = {
                identificationNumber: true,
                firstName: true,
                lastName: true,
                address: true,
                email: true,
                phone: true
            };

            const toComplete = [];
            if (!this.isValidEmail) {
                toComplete.push("Correo");
            }

            if (!this.isValidIdentificationNumber) {
                toComplete.push("Número de identificación");
            }

            if (!this.isValidFirstName) {
                toComplete.push("Nombre");
            }

            if (!this.isValidLastName) {
                toComplete.push("Apellido");
            }

            if (!this.isValidPhone) {
                toComplete.push("Teléfono");
            }

            if (this.needAddress && !this.isValidAddress) {
                toComplete.push("Dirección");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Cliente invalido",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        onBlurAddress() {
            this.introValidators.address = true;
            this.itemComponent.address = this.itemComponent.address.trim();
        }
    }
};
</script>
